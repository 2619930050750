import {
  Box,
  Button,
  Center,
  FormControl,
  Input,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  Text,
  PinInput,
  PinInputField,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Card, CardBody, HStack, Table, Thead, Th, Tbody, Tr, Td
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useAppDataStore} from "../../mobx/appDataStore";
import n_logo from "../../assets/p_video/logo.png"
import moment from "moment";

export default function LoginTvAmazon() {
  const appDataStore = useAppDataStore()
  const socket = appDataStore.socket
  const params = useParams()
  const [tvCode, setTvCode] = useState()
  const [busy, setBusy] = useState(false)
  const [result, setResult] = useState()
  const {isOpen, onOpen, onClose} = useDisclosure()
  let [buttonCount, setButtonCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isValid, setIsValid] = useState(false)
  const [validatorResponse, setValidatorResponse] = useState(null)
  const [messages, setMessages] = useState([])

  const login = async () => {
    setBusy(true)
    setResult(null)
    try {
      const res = await appDataStore.post('/amazon/activate', {linkId: params.code, tvCode})
      setResult(res)
      if (res.status === 'error') {
        onOpen()
        doCount()
      }
    } catch (err) {
      setResult({status: 'error', message: err.message})
      onOpen()
      doCount()
    }
    setBusy(false)

  }

  const getLinkInfo = async () => {
    setLoading(true)
    setIsValid(false)
    try {
      const res = await appDataStore.post('/amazon/validate', {linkId: params.code})
      if (res.result === 'success') setIsValid(true)
      setValidatorResponse(res)
    } catch (err) {
      setValidatorResponse({status: 'error', message: 'No se pudo establecer comunicación con el servidor'})
    }
    setLoading(false)

  }
  const doCount = () => {
    setButtonCount(2)
  }

  useEffect(() => {
    if (buttonCount > 0)
      setTimeout(() => setButtonCount(prev => prev - 1), 1000)
  }, [buttonCount]);

  useEffect(() => {
    getLinkInfo()
  }, []);

  const fetchData = () => {
    socket.emit('digital-account/messages', {linkId: params.code}, (res) => {
      setMessages(res.messages)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const viewCode = async (id) => {
    socket.emit('digital-account/messages/view', {linkId: params.code, id}, (res) => {
      if (res.result === 'success') {
        setMessages([res.message])
      } else {
        alert(res.error)
      }
    })
  }

  if (loading) return (<Box>
    <Center>
      <Text>
        Cargando...
      </Text>
    </Center>
  </Box>)

  if (!isValid) return (
    <Box padding={10}>
      <Center>
        <Text color={'red'} fontSize={'1.5em'} textAlign={'center'}>Este link ya fué utilizado y solo es válido para
          iniciar sesión en 1 dispositivo </Text>
      </Center>
      <Center>
        <Text color={'white'}> Fecha de uso: {validatorResponse.usedAt} </Text>
      </Center>
      <Center>
        <Text color={'white'}> Dispositivo: {validatorResponse.deviceName} </Text>
      </Center>
      <Center>
        <Text color={'white'}> Perfil asignado: {validatorResponse.profile} </Text>
      </Center>
      <Center display={'block'}>
        <Table mb={2} display={''}>
          <Thead>
            <Th>Código</Th>
            <Th>Fecha y hora</Th>
          </Thead>
          <Tbody>
            {messages.map(({id, code,type, createdAt}) => {
              return (
                <Tr>
                  {code && type === 'code' && <Td>{code}</Td>}
                  {code && type === 'url' && <Td><a href={code} target={'_blank'}>Click para Acceder</a></Td>}
                  {!code && <Td><Button onClick={() => viewCode(id)}>ver código</Button></Td>}

                  <Td>{moment(createdAt).fromNow()}</Td>
                </Tr>
              )
            })}
            {messages.length === 0 &&
              <Tr>
                <Td colSpan={2}>No hay códigos</Td>
              </Tr>}
          </Tbody>
        </Table>
        <Center display={'block'}>
          <Button onClick={fetchData}>Actualizar</Button>
        </Center>
      </Center>
    </Box>)

  return (<div className="center">
    <Box
      p={{sm: 3, base: 5, lg: 3}}
      pt={1}
    >
      <form>
        <Center
          className={'mt-1 mb-2'}
          fontWeight={'bold'}
        >Has adquirido una pantalla (Perfil) de:</Center>
        <Center>
          <img src={n_logo}/>
        </Center>
        <FormControl className={'mb-2 mt-3'}>
          <Center>
            <Flex maxWidth={'100%'}>
              {result?.status !== 'success' ?
                <>
                  <HStack>
                    <PinInput
                      autoFocus={true}
                      manageFocus={true}
                      placeholder={'-'}
                      type='alphanumeric'
                      color={'#000000'}
                      backgroundColor={'#FFFFFF'}
                      //value={tvCode}
                      onComplete={code => {
                        setTvCode(code)
                      }}
                    >
                      <PinInputField marginX={1} c color={'#000000'} backgroundColor={'#FFFFFF'}/>
                      <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                      <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                      <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                      <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                      <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                    </PinInput>
                  </HStack>
                  <Button
                    hideBelow='lg'
                    marginLeft={4}
                    paddingX={10}
                    color={'#FFFFFF'}
                    backgroundColor={'#0092ff'}
                    borderColor={'#0092ff'}
                    colorScheme=''
                    onClick={login} variant='outline'
                    loadingText='Por favor espere...'
                    isLoading={busy}>
                    Iniciar Sesión
                  </Button> </> : null}
            </Flex>
          </Center>
          {result?.status !== 'success' ?
            <Button
              marginTop={5}
              hideFrom={'lg'}
              marginLeft={4}
              paddingX={10}
              color={'#FFFFFF'}
              backgroundColor={'#0092ff'}
              borderColor={'#0092ff'}
              colorScheme=''
              onClick={login} variant='outline'
              loadingText='Por favor espere...'
              isLoading={busy}>
              Iniciar Sesión
            </Button> : null}
        </FormControl>
        {result ?
          <Alert status={result.status} color={'#000000'}>
            <AlertIcon/>
            <AlertTitle>{result.message}</AlertTitle>
          </Alert> : null}
        {result && result.status === 'error' ?
          <Modal colorScheme={'blackAlpha'} closeOnOverlayClick={false} closeOnEsc={false} onClose={onClose}
                 isOpen={isOpen} isCentered
                 size={'xl'}>
            <ModalOverlay/>
            <ModalContent>
              <ModalHeader minH={200}>
                <Center>
                  <Text marginTop={50} size={'xl'} color={'red'}>{result.message}</Text>
                </Center>
              </ModalHeader>
              <ModalBody></ModalBody>
              <ModalFooter>
                <Button
                  isLoading={buttonCount > 0}
                  loadingText={`${buttonCount} Cerrar`}
                  colorScheme='blue'
                  variant='outline'
                  onClick={onClose}
                >Cerrar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal> : null}
      </form>
    </Box>
  </div>)
}