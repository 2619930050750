import React, {useEffect, useState} from "react";
import fondo from "../../assets/top-movie/fondo2.png"
import './style.css'

import {
  Box, Button,
  Center,
  Grid,
  GridItem,
  Image,
  SimpleGrid, Table, Tbody, Td, Th, Thead, Tr,
} from "@chakra-ui/react";
import logo from "../../assets/top-movie/logo.png"
import header from "../../assets/top-movie/header2.png"
import {useLocation, useParams} from "react-router";
import {useAppDataStore} from "../../mobx/appDataStore";
import moment from "moment";
import 'moment/locale/es'

moment.locale('es')
const setBackground = () => {

  // Create our stylesheet
  const style = document.createElement('style')
  style.innerHTML =
    `body {
      background: url("${fondo}")  no-repeat center center fixed;
      color: white;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
  }
  
   @media screen and (max-width: 62em) {
   
      body {
      background-image: url("${fondo}");
            background-size: cover;
      } 
   }
  `

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)
}

const InfoTopMovie = () => {
  const appDataStore = useAppDataStore()
  const [accountData, setAccountData] = useState()
  const [redeemData, setRedeemData] = useState()
  const [giftCardMessages, setGiftCardMessages] = useState([])

  const {webToken} = useParams()
  const socket = appDataStore.socket

  useEffect(() => {
    document.title = 'Disfruta tu cuenta! - Top Movie'
    setBackground()
  }, []);

  const fetchData = () => {
    socket.emit('redeem/info', {webToken}, (res) => {
      setRedeemData(res.redeem)
      setAccountData(res.redeem.accountData)
      setGiftCardMessages(res.redeem.GiftCardMessages)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const downloadInfo = async () => {
    window.open(`/api/v1/gift-cards/download/${accountData?.code}`)
  }

  const viewCode = async (id) => {
    socket.emit('redeem/seeCode', {webToken, id}, (res) => {
      if (res.result === 'success') {
        setGiftCardMessages([res.giftCardMessage])
      } else {
        alert(res.error)
      }
    })
  }

  return (
    <>
      <Grid
        templateColumns='repeat(2, 1fr)'
        gridTemplateColumns={'25% 1fr'}
        w={'95vw'}>
        <GridItem>
          <Center>
            <Image src={logo} alt={'Has adquirido una gift card'}/>
          </Center>
        </GridItem>
        <GridItem>
          <Center>
            <Image src={header} maxWidth={{base: '100%', lg: '80%'}} alt={'Has adquirido una gift card'}/>
          </Center>
        </GridItem>
      </Grid>
      <Box marginBottom={'30px'}>
        <Center>
          <Box maxWidth={'70%'} padding={'40px'} background={'blackAlpha.600'} borderRadius={'35px'}
               fontSize={{base: '1em', lg: 'larger'}}
               fontWeight={'bold'}>
            <Center style={{textAlign: 'center'}}>
              La vigencia de duración de tu plataforma de entretenimiento es de 27 a 30 días a
              partir de este momento,
              recuerda que es un ACCESO ÚNICO para (1) solo dispositivo
            </Center>
          </Box>
        </Center>
      </Box>
      <SimpleGrid columns={{base: 1, lg: 2}}>
        <Box>
          <Center>
            <Box mb={3} w={'70%'} borderRadius={'25px'} paddingY={'10px'} fontSize={'2em'} fontWeight={'bold'}
                 color={'#442670'} backgroundColor={'#FFF'}>
              <Center>
                Datos de acceso
              </Center>
            </Box>
          </Center>

          <Center>
            <Box w={'70%'} marginBottom={'15px'} borderRadius={'25px'} paddingY={'10px'} fontSize={'1em'}
                 fontWeight={'bold'}
                 paddingX={'15px'}
                 color={'#442670'} backgroundColor={'#FFF'}>
              <p>Tipo de Cuenta:</p>
              <p>{accountData?.digital_account}</p>
              <p>Usuario:</p>
              <p>{accountData?.login}</p>
              <p>Clave:</p>
              <p>{accountData?.password}</p>
              <p>Perfil:</p>
              <p>{accountData?.screen_profile}</p>
              <p>Pin Perfil:</p>
              <p>{accountData?.screen_pin}</p>
              <p>Inicio de sesión:</p>
              <p><a href={accountData?.url}>{accountData?.url}</a></p>
              <p>Instrucciones de uso:</p>
              <p>{redeemData?.GiftCardCode.instructionsUrl &&
                <a target={'_blank'}
                   href={redeemData?.GiftCardCode.instructionsUrl}>{redeemData?.GiftCardCode.instructionsUrl}</a>}</p>
              <p>Info:</p>
              <p>{redeemData?.GiftCardCode.productInfo}</p>
            </Box>
          </Center>
        </Box>
        <Box>
          <Center>
            <Box w={'70%'} borderRadius={'25px'} paddingY={'10px'} fontSize={'2em'} fontWeight={'bold'}
                 color={'#442670'} backgroundColor={'#FFF'}>
              <Center style={{textAlign: 'center'}}>
                Códigos para inicio de sesión
              </Center>
            </Box>
          </Center>
          <Center>
            <Box marginBottom={'15px'} marginTop={'15px'} w={'70%'} borderRadius={'25px'} paddingY={'10px'}
                 fontSize={'1em'}
                 fontWeight={'bold'}
                 paddingX={'15px'}
                 color={'#442670'} backgroundColor={'#FFF'}>

              <Table mb={2}>
                <Thead>
                  <Th>Código</Th>
                  <Th>Fecha y hora</Th>
                </Thead>
                <Tbody>
                  {giftCardMessages.map(({id, code, createdAt}) => {
                    return (
                      <Tr>
                        {code ? <Td>{code}</Td> :
                          <Td><Button onClick={() => viewCode(id)}>ver código</Button></Td>}
                        <Td>{moment(createdAt).fromNow()}</Td>
                      </Tr>
                    )
                  })}
                  {giftCardMessages.length === 0 &&
                    <Tr>
                      <Td colSpan={2}>No hay códigos</Td>
                    </Tr>}
                </Tbody>
              </Table>
              <Center>
                <Button onClick={fetchData}>Actualizar</Button>
              </Center>

            </Box>
          </Center>

        </Box>
      </SimpleGrid>
    </>
  )

}

export default InfoTopMovie
